import { Box, Button, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { ReviewHotelItineraryConnectorProps } from "./container";
import {
  getCancellationPolicyInfo,
  HotelNeedToKnowPanel,
  HotelSummaryPanel,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import {
  formattedCheckIn,
  formattedCheckOut,
  NEED_TO_KNOW_DETAILS,
  NEED_TO_KNOW_TITLE,
  CHECK_IN_INSTRUCTIONS_TITLE,
  CHANGE_HOTEL,
  CHANGE_ROOM,
} from "./constants";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import {
  CHANGE_HOTEL_SELECTED,
  CHANGE_ROOM_SELECTED,
  CheckInPolicy,
} from "redmond";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/trackEvent";

interface IReviewHotelItineraryProps
  extends ReviewHotelItineraryConnectorProps,
    RouteComponentProps {
  setOpenChangeHotelModal?: (arg: boolean) => void;
  setOpenChangeRoomModal?: (arg: boolean) => void;
  showCardContentIcons?: boolean;
}

export const ReviewHotelItinerary = ({
  selectedLodging,
  checkIn,
  checkOut,
  chosenProduct,
  selectedPackageByLodging,
  setOpenChangeHotelModal,
  setOpenChangeRoomModal,
  showCardContentIcons = true,
  roomInfo,
}: IReviewHotelItineraryProps) => {
  const { matchesMobile } = useDeviceTypes();

  if (!selectedPackageByLodging) return null;

  const mergedPolicies = useMemo(
    () =>
      [...(chosenProduct?.policies ?? [])].reduce(
        (uniquePolicies, currentPolicy) => {
          if (
            !uniquePolicies.some(({ title }) => currentPolicy.title === title)
          ) {
            uniquePolicies.push(currentPolicy);
          }
          return uniquePolicies;
        },
        [] as CheckInPolicy[]
      ),
    [chosenProduct?.policies]
  );

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(chosenProduct?.cancellationPolicy)
    : null;

  return (
    <Box
      className={clsx("review-pkg-hotel-itinerary", { mobile: matchesMobile })}
    >
      <Box className="review-itinerary-card-content-container hotel-summary-panel-section">
        <HotelSummaryPanel
          selectedLodging={selectedLodging}
          checkIn={checkIn || null}
          checkOut={checkOut || null}
          isMobile={matchesMobile}
          showCollectionBanner={false}
          roomInfo={roomInfo?.roomInfo}
          showRoomCapacityInfo
        />
        {!!setOpenChangeHotelModal || !!setOpenChangeRoomModal ? (
          <Box className="change-ctas-container">
            {!!setOpenChangeHotelModal && (
              <Button
                onClick={() => {
                  setOpenChangeHotelModal(true);
                  trackEvent({
                    eventName: CHANGE_HOTEL_SELECTED,
                    properties: {},
                    encryptedProperties: [],
                  });
                }}
                className="change-cta"
              >
                <Typography className="change-cta-text">
                  {CHANGE_HOTEL}
                </Typography>
              </Button>
            )}

            {!!setOpenChangeRoomModal && (
              <>
                <Typography className="ctas-separator">|</Typography>
                <Button
                  onClick={() => {
                    setOpenChangeRoomModal(true);
                    trackEvent({
                      eventName: CHANGE_ROOM_SELECTED,
                      properties: {},
                      encryptedProperties: [],
                    });
                  }}
                  className="change-cta"
                >
                  <Typography className="change-cta-text">
                    {CHANGE_ROOM}
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        ) : null}
      </Box>

      {selectedLodging?.lodging?.checkInInstructions && checkIn && checkOut && (
        <Box
          className={clsx(
            "review-itinerary-card-content-container",
            "check-in-instructions"
          )}
        >
          {showCardContentIcons && <Icon name={IconName.CheckInIcon} />}
          <Box className="text-container">
            <Typography
              variant="h2"
              className="hotel-shop-check-in-instructions-heading section-header"
            >
              {CHECK_IN_INSTRUCTIONS_TITLE}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: formattedCheckIn(
                  checkIn,
                  selectedLodging.lodging.checkInInstructions?.checkInTime
                ),
              }}
            />
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: formattedCheckOut(
                  checkOut,
                  selectedLodging.lodging.checkInInstructions?.checkOutTime
                ),
              }}
            />
          </Box>
        </Box>
      )}
      {cancellationPolicyInfo ? (
        <Box
          className={clsx(
            "review-itinerary-card-content-container",
            "cancellation-details"
          )}
        >
          {showCardContentIcons && <Icon name={IconName.ErrorState} />}

          <Box className="text-container">
            <Typography variant="h2" className="section-header">
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="body2">
              {cancellationPolicyInfo.secondaryText}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <HotelNeedToKnowPanel
        title={NEED_TO_KNOW_TITLE}
        subtitle={NEED_TO_KNOW_DETAILS}
        policies={mergedPolicies}
        fetchingPriceQuote={false}
      />
    </Box>
  );
};
