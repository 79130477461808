export const NEXT_TEXT = "Next";
export const WHERE_FROM = "Where from?";
export const WHERE_TO = "Where to?";
export const ADD_FLIGHT_BUTTON_TEXT = "+ Add flight";
export const REMOVE_FLIGHT_BUTTON_TEXT = "Remove flight";
export const CONTINUE = "Continue";
export const SEARCH_FLIGHTS = "Search Flights";
export const MAX_ROUTES_MESSAGE =
  "You’ve reached the maximum number of multi-city flights on a single booking.";
export const OUTBOUND = "Outbound";
export const OUTBOUND_DATE = "Outbound date";
export const OUTBOUND_TO = "Outbound to";
export const OUTBOUND_FROM = "Outbound from";
export const CHOOSE_OUTBOUND_DATE = "Choose outbound date";
export const MISSING_ROUTE_FIELDS_ERROR =
  "Please complete all required fields.";
export const UPDATE_CALENDAR_TEXT = "Updating your calendar";

export const TRAVELERS_SELECT_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "Ages 2 to 17",
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
  infantLapTitle: "Infants on lap",
  infantLapSubtitle: "Under the age of 2",
  infantSeatTitle: "Infants in seat",
  infantSeatSubtitle: "Under the age of 2",
  infantTitle: "Infants",
  infantSubtitle: "Younger than 2",
};

export const MISSING_INFO_SEARCH_ERROR =
  "To continue, please complete all required fields.";
