import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { MobilePackagesSearchControlConnectorProps } from "./container";
import "./styles.scss";
import {
  ActionLink,
  ButtonWrap,
  CloseButtonIcon,
  Header,
  Icon,
  IconName,
} from "halifax";
import clsx from "clsx";
import { PATH_HOME, PATH_HOTELS_AVAILABILITY } from "../../../../utils/paths";
import {
  MOBILE_SEARCH_CONTROL_CALENDAR_TITLE,
  MOBILE_SEARCH_CONTROL_TITLE,
} from "./textConstants";
import { LocationSearch, MobileCalendarPicker } from "./components";
import dayjs from "dayjs";
import { INFO_MODAL_CTA_TEXT } from "../../textConstants";
import { PackagesInfoModal } from "../";
import { transformToStringifiedAvailabilityQuery } from "../../../availability/utils/queryStringHelpers";
import { IIdLodgings } from "redmond";
import {
  IPackagesParsedQuery,
  parseQueryString,
} from "../../utils/parseQueryString";
import { fetchFlightLocation } from "../../../../api/v0/search/fetchFlightLocation";
import { fetchHotelLocation } from "../../../../api/v0/search/fetchHotelLocation";

enum MobilePackagesSearchStep {
  LocationSearch,
  CalendarPicker,
  Complete,
}

export interface IMobilePackagesSearchControlProps
  extends MobilePackagesSearchControlConnectorProps,
    RouteComponentProps {}

export const MobilePackagesSearchControl = ({
  history,
  departureDate,
  returnDate,
  destination,
  origin,
  travelers,
  stopsOptions,
  fareClassOptionFilter,
  setDepartureDate,
  setReturnDate,
  setOrigin,
  setDestination,
  setStopsOption,
  setFareclassOptionFilter,
}: IMobilePackagesSearchControlProps) => {
  const [currentStep, setCurrentStep] =
    React.useState<MobilePackagesSearchStep>(
      MobilePackagesSearchStep.LocationSearch
    );

  const [packagesInfoModalOpen, setPackagesInfoModalOpen] =
    React.useState<boolean>(false);

  const handleGoBack = () => {
    setCurrentStep((step) => {
      return step > MobilePackagesSearchStep.LocationSearch ? step - 1 : step;
    });
  };

  const handleSearchClick = () => {
    if (
      !!destination &&
      !!origin &&
      !!departureDate &&
      !!returnDate &&
      !!travelers.adultsCount
    ) {
      history.push(
        `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          origin?.id?.code?.code,
          (destination?.id as IIdLodgings).lodgingSelection.searchTerm,
          (destination?.id as IIdLodgings).lodgingSelection.placeId,
          departureDate,
          returnDate,
          travelers.adultsCount,
          travelers.children,
          travelers.infants,
          stopsOptions,
          fareClassOptionFilter
        )}`
      );
    }
  };

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const parsedQueryString = parseQueryString(history);

  const populateFieldsFromQueryString = async ({
    fromDate,
    untilDate,
    origin,
    destination,
    stopsOption,
    fareClass,
  }: IPackagesParsedQuery) => {
    if (fromDate) {
      setDepartureDate(fromDate);
    }

    if (untilDate) {
      setReturnDate(untilDate);
    }

    if (origin) {
      const originLocation = await fetchFlightLocation(origin);
      setOrigin(originLocation);
    }

    if (destination) {
      const destinationLocation = await fetchHotelLocation(destination);
      setDestination(destinationLocation);
    }

    if (stopsOption) {
      setStopsOption(stopsOption);
    }

    if (fareClass) {
      setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
        [fareClass]: true,
      });
    }
  };

  useEffect(() => {
    if (parsedQueryString) {
      (async () => {
        await populateFieldsFromQueryString(parsedQueryString);
      })();
    }
  }, []);

  return (
    <Box className="mobile-packages-search">
      <Header
        className="homepage"
        center={
          <Box className="header-center-section">
            {currentStep === MobilePackagesSearchStep.CalendarPicker ? (
              <Box className="calendar-header">
                <Typography variant="body1">
                  {MOBILE_SEARCH_CONTROL_CALENDAR_TITLE}
                </Typography>
              </Box>
            ) : (
              <Box className="mobile-packages-search-location-label">
                <Typography variant="body1">
                  {MOBILE_SEARCH_CONTROL_TITLE}
                </Typography>
              </Box>
            )}
          </Box>
        }
        left={
          <ActionLink
            className={clsx("mobile-packages-search-header-go-back", {
              hidden: currentStep === MobilePackagesSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<Icon name={IconName.RightChevronIcon} />}
          />
        }
        right={
          <ActionLink
            className="mobile-packages-search-header-close"
            onClick={() => {
              history.push(PATH_HOME);
              //   hasLocationAutocompleteError &&
              //     setLocationAutocompleteError(false);
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobilePackagesSearchStep.LocationSearch && (
        <>
          <LocationSearch
            onComplete={() => {
              setCurrentStep(MobilePackagesSearchStep.CalendarPicker);
            }}
            history={history}
          />
          <ButtonWrap
            className="packages-info-cta"
            onClick={() => setPackagesInfoModalOpen(true)}
          >
            <Box className="new-tag">New</Box>
            <Typography className="packages-info-cta-text">
              {INFO_MODAL_CTA_TEXT}
            </Typography>
            <Icon name={IconName.InfoCircle} />
          </ButtonWrap>

          <PackagesInfoModal
            open={packagesInfoModalOpen}
            setOpen={setPackagesInfoModalOpen}
            isMobile
          />
        </>
      )}
      {currentStep === MobilePackagesSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          focusedMonthIndex={focusedMonthIndex}
          returnDate={returnDate}
          departure={departureDate}
          onComplete={handleSearchClick}
        />
      )}
    </Box>
  );
};
