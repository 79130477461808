import {
  BoundingBox,
  LodgingId,
  AmenityEnum,
  HotelStarRatingEnum,
  IResult,
  AppliedHotelFilterProperties,
  ICategorizedResponse,
  UserHotelPreferencesPayload,
  Payment,
  SearchPackagesRequestEnum,
  ITripTerminus,
  StayTypesEnum,
  FareclassOptionFilter,
  APPLIED_PACKAGES_FILTER,
  SliceStopCountFilter,
  SearchPackageResponseSuccess,
  SearchPackageResponseFailure,
} from "redmond";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import * as H from "history";
// import { PackagesAvailabilitySortOption } from "../reducer/state";
import * as actionTypes from "./constants";
import { PackagesHotelsAvailabilitySortOption } from "../reducer/state";
import { getSelectedFareClass } from "../../../utils/fareClass";

export interface IFetchPackagesAvailability {
  type:
    | actionTypes.FETCH_INITIAL_PACKAGES_AVAILABILITY
    | actionTypes.FETCH_MORE_PACKAGES_AVAILABILITY;
  requestType: SearchPackagesRequestEnum;
  history: H.History;
}

export interface IStopFetchMorePackagesAvailability {
  type: actionTypes.STOP_FETCH_MORE_PACKAGES_AVAILABILITY;
}

export const fetchInitialPackagesAvailability = (
  history: H.History
): IFetchPackagesAvailability => ({
  type: actionTypes.FETCH_INITIAL_PACKAGES_AVAILABILITY,
  requestType: SearchPackagesRequestEnum.InitialRequest,
  history,
});

export const fetchMorePackagesAvailability = (
  history: H.History
): IFetchPackagesAvailability => ({
  type: actionTypes.FETCH_MORE_PACKAGES_AVAILABILITY,
  requestType: SearchPackagesRequestEnum.FollowUpRequest,
  history,
});

export const stopFetchMorePackagesAvailability =
  (): IStopFetchMorePackagesAvailability => ({
    type: actionTypes.STOP_FETCH_MORE_PACKAGES_AVAILABILITY,
  });

export interface ISetPackagesAvailabilityResults {
  type: actionTypes.SET_PACKAGES_AVAILABILITY_RESULTS;
  payload: ISetPackagesAvailabilityResultsPayload;
}

export type ISetPackagesAvailabilityResultsPayload =
  SearchPackageResponseSuccess["value"];

export const setPackagesAvailabilityResults = (args: {
  payload: ISetPackagesAvailabilityResultsPayload;
}): ISetPackagesAvailabilityResults => ({
  type: actionTypes.SET_PACKAGES_AVAILABILITY_RESULTS,
  ...args,
});

export interface ISetPackagesAvailabilityErrors {
  type: actionTypes.SET_PACKAGES_AVAILABILITY_ERRORS;
  errors: ISetPackagesAvailabilityErrorsPayload;
}

export type ISetPackagesAvailabilityErrorsPayload =
  SearchPackageResponseFailure["errors"];

export const setPackagesAvailabilityErrors = (
  errors: ISetPackagesAvailabilityErrorsPayload
): ISetPackagesAvailabilityErrors => ({
  type: actionTypes.SET_PACKAGES_AVAILABILITY_ERRORS,
  errors,
});

export const setPackagesAvailabilityCallStateFailed =
  (): ISetPackagesAvailabilityCallStateFailed => ({
    type: actionTypes.SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface ISetPackagesAvailabilityCallStateFailed {
  type: actionTypes.SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED;
}

export const resetPackagesAvailabilityCallState =
  (): IResetPackagesAvailabilityCallState => ({
    type: actionTypes.RESET_PACKAGES_AVAILABILITY_CALL_STATE,
  });

export interface IResetPackagesAvailabilityCallState {
  type: actionTypes.RESET_PACKAGES_AVAILABILITY_CALL_STATE;
}

export interface ISetAmenitiesFilter {
  type: actionTypes.SET_AMENITIES_FILTER;
  amenities: AmenityEnum[];
}

export const setAmenitiesFilter = (
  amenities: AmenityEnum[]
): ISetAmenitiesFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "amenities",
    amenities: amenities,
  };
  if (amenities.length > 0) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_AMENITIES_FILTER,
    amenities,
  };
};

export interface ISetStarRatingsFilter {
  type: actionTypes.SET_STAR_RATINGS_FILTER;
  starRatings: HotelStarRatingEnum[];
}

export const setStarRatingsFilter = (
  starRatings: HotelStarRatingEnum[]
): ISetStarRatingsFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "stars",
  };
  if (starRatings.length > 0) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_STAR_RATINGS_FILTER,
    starRatings,
  };
};

export interface ISetStayTypeFilter {
  type: actionTypes.SET_STAY_TYPE_FILTER;
  stayTypes: StayTypesEnum[];
}

export const setStayTypeFilter = (
  stayTypes: StayTypesEnum[]
): ISetStayTypeFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "stay_types",
  };
  if (stayTypes.length > 0) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_STAY_TYPE_FILTER,
    stayTypes,
  };
};

export interface ISetMaxPriceFilter {
  type: actionTypes.SET_MAX_PRICE_FILTER;
  maxPrice: number;
}

export const setMaxPriceFilter = (maxPrice: number): ISetMaxPriceFilter => {
  if (maxPrice) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties: {
        filter_type: "max_price",
      },
    });
  }
  return {
    type: actionTypes.SET_MAX_PRICE_FILTER,
    maxPrice,
  };
};

export interface ISetHotelNameFilter {
  type: actionTypes.SET_HOTEL_NAME_FILTER;
  hotelName: string;
}

export const setHotelNameFilter = (hotelName: string): ISetHotelNameFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "hotel_name",
  };
  if (!!hotelName) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_HOTEL_NAME_FILTER,
    hotelName,
  };
};

export interface ISetFreeCancelFilter {
  type: actionTypes.SET_FREE_CANCEL_FILTER;
  freeCancel: boolean;
}

export const setFreeCancelFilter = (
  freeCancel: boolean
): ISetFreeCancelFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "free_cancel",
  };
  if (freeCancel) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_FREE_CANCEL_FILTER,
    freeCancel,
  };
};

export interface ISetPackagesHotelsAvailabilitySortOption {
  type: actionTypes.SET_PACKAGES_HOTELS_SORT_OPTION;
  sortOption: PackagesHotelsAvailabilitySortOption;
}

export const setPackagesHotelsAvailabilitySortOption = (
  sortOption: PackagesHotelsAvailabilitySortOption
): ISetPackagesHotelsAvailabilitySortOption => ({
  type: actionTypes.SET_PACKAGES_HOTELS_SORT_OPTION,
  sortOption,
});

export interface ISetMapBound {
  type: actionTypes.SET_MAP_BOUND;
  mapBound: BoundingBox | null;
}

export const setMapBound = (mapBound: BoundingBox | null): ISetMapBound => ({
  type: actionTypes.SET_MAP_BOUND,
  mapBound,
});

export interface ISetLodgingIdInFocus {
  type: actionTypes.SET_LODGING_ID_IN_FOCUS;
  lodgingId: LodgingId | null;
}

export const setLodgingIdInFocus = (
  lodgingId: LodgingId | null
): ISetLodgingIdInFocus => ({
  type: actionTypes.SET_LODGING_ID_IN_FOCUS,
  lodgingId,
});

export interface ISetLodgingIdHovered {
  type: actionTypes.SET_LODGING_ID_HOVERED;
  lodgingId: LodgingId | null;
}

export const setLodgingIdHovered = (
  lodgingId: LodgingId | null
): ISetLodgingIdHovered => ({
  type: actionTypes.SET_LODGING_ID_HOVERED,
  lodgingId,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetSearchedDates {
  type: actionTypes.SET_SEARCHED_DATES;
  searchedFromDate: Date;
  searchedUntilDate: Date;
}

export const setSearchedDates = (
  searchedFromDate: Date,
  searchedUntilDate: Date
): ISetSearchedDates => ({
  type: actionTypes.SET_SEARCHED_DATES,
  searchedFromDate,
  searchedUntilDate,
});

export interface ISetSearchedDestinationResult {
  type: actionTypes.SET_SEARCHED_DESTINATION_RESULT;
  searchedDestinationResult: IResult | null;
}

export const setSearchedDestinationResult = (
  searchedDestinationResult: IResult | null
): ISetSearchedDestinationResult => ({
  type: actionTypes.SET_SEARCHED_DESTINATION_RESULT,
  searchedDestinationResult,
});

export interface ISetSearchedOriginResult {
  type: actionTypes.SET_SEARCHED_ORIGIN_RESULT;
  searchedOriginResult: ITripTerminus | null;
}

export const setSearchedOriginResult = (
  searchedOriginResult: ITripTerminus | null
): ISetSearchedOriginResult => ({
  type: actionTypes.SET_SEARCHED_ORIGIN_RESULT,
  searchedOriginResult,
});

export interface ISetHasViewedUnavailableHotel {
  type: actionTypes.SET_HAS_VIEWED_UNAVAILABLE_HOTEL;
}

export const setHasViewedUnavailableHotel = () => ({
  type: actionTypes.SET_HAS_VIEWED_UNAVAILABLE_HOTEL,
});

export interface ISetSelectedLodgingIndex {
  type: actionTypes.SET_SELECTED_LODGING_INDEX;
  index: number;
}

export const setSelectedLodgingIndex = (index: number) => ({
  type: actionTypes.SET_SELECTED_LODGING_INDEX,
  index,
});

export interface ISetSearchedTravelers {
  type: actionTypes.SET_SEARCHED_TRAVELERS;
  travelers: {
    adultsCount: number;
    children: number[];
    infants: { age: number; inSeat: boolean }[];
  };
}

export const setSearchedTravelers = (travelers: {
  adultsCount: number;
  children: number[];
  infants: { age: number; inSeat: boolean }[];
}): ISetSearchedTravelers => ({
  type: actionTypes.SET_SEARCHED_TRAVELERS,
  travelers,
});

export interface ISetSearchedRoomsCount {
  type: actionTypes.SET_SEARCHED_ROOMS_COUNT;
  rooms: number;
}

export const setSearchedRoomsCount = (
  rooms: number
): ISetSearchedRoomsCount => ({
  type: actionTypes.SET_SEARCHED_ROOMS_COUNT,
  rooms,
});

export interface ISetStopsOption {
  type: actionTypes.SET_STOPS_OPTION;
  stopsOption: SliceStopCountFilter;
}

export const setStopsOption = (
  stopsOption: SliceStopCountFilter
): ISetStopsOption => {
  return {
    type: actionTypes.SET_STOPS_OPTION,
    stopsOption,
  };
};

export interface ISetFareclassOptionFilter {
  type: actionTypes.SET_FARECLASS_OPTION_FILTER;
  fareclassOptionFilter: FareclassOptionFilter;
}

export const setFareclassOptionFilter = (
  fareclassOptionFilter: FareclassOptionFilter
): ISetFareclassOptionFilter => {
  const selectedFareClass = getSelectedFareClass(fareclassOptionFilter);
  if (selectedFareClass) {
    trackEvent({
      eventName: APPLIED_PACKAGES_FILTER,
      properties: {
        filter_type: "fareclass",
        fareclass: selectedFareClass,
      },
    });
  }
  return {
    type: actionTypes.SET_FARECLASS_OPTION_FILTER,
    fareclassOptionFilter,
  };
};

export interface IFetchViewHotelsNearLocationCategories {
  type: actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;
  queryString: string;
}

export const fetchViewHotelsNearLocationCategories = (
  queryString: string
): IFetchViewHotelsNearLocationCategories => ({
  type: actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES,
  queryString,
});

export interface ISetViewHotelsNearLocationCategories {
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setViewHotelsNearLocationCategories = (
  categories: ICategorizedResponse[]
): ISetViewHotelsNearLocationCategories => ({
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES,
  categories,
});

export interface ISetViewHotelsNearLocation {
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION;
  location: IResult | null;
}

export const setViewHotelsNearLocation = (
  location: IResult | null
): ISetViewHotelsNearLocation => ({
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION,
  location,
});

// Corporate Travel
export interface ISetLoyaltyProgramsFilter {
  type: actionTypes.SET_LOYALTY_PROGRAMS_FILTER;
  loyaltyPrograms: string[];
}

export const setLoyaltyProgramsFilter = (
  loyaltyPrograms: string[]
): ISetLoyaltyProgramsFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "loyalty_programs",
  };
  trackEvent({
    eventName: APPLIED_PACKAGES_FILTER,
    properties,
  });

  return {
    type: actionTypes.SET_LOYALTY_PROGRAMS_FILTER,
    loyaltyPrograms,
  };
};

export interface ISetPolicyFilter {
  type: actionTypes.SET_POLICY_FILTER;
  isInPolicy: boolean;
}

export const setPolicyFilter = (isInPolicy: boolean) => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "policy_status",
  };
  trackEvent({
    eventName: APPLIED_PACKAGES_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_POLICY_FILTER,
    isInPolicy,
  };
};

export const fetchUserHotelPreferences = (): IFetchUserHotelPreferences => ({
  type: actionTypes.FETCH_USER_HOTEL_PREFERENCES,
});

export interface IFetchUserHotelPreferences {
  type: actionTypes.FETCH_USER_HOTEL_PREFERENCES;
}

export const setUserHotelPreferences = (args: {
  hotelPreferences: UserHotelPreferencesPayload;
}): ISetUserHotelPreferences => ({
  type: actionTypes.SET_USER_HOTEL_PREFERENCES,
  hotelPreferences: args.hotelPreferences,
});

export interface ISetUserHotelPreferences {
  type: actionTypes.SET_USER_HOTEL_PREFERENCES;
  hotelPreferences: UserHotelPreferencesPayload;
}

export const setUserHotelPreferencesCallStateFailed =
  (): ISetUserHotelPreferencesCallStateFailed => ({
    type: actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUserHotelPreferencesCallStateFailed {
  type: actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;
}

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export type PackagesAvailabilityActions =
  | IFetchPackagesAvailability
  | ISetPackagesAvailabilityResults
  | ISetPackagesAvailabilityCallStateFailed
  | IResetPackagesAvailabilityCallState
  | ISetAmenitiesFilter
  | ISetLoyaltyProgramsFilter
  | ISetStarRatingsFilter
  | ISetMaxPriceFilter
  | ISetHotelNameFilter
  | ISetPackagesHotelsAvailabilitySortOption
  | ISetMapBound
  | ISetOpenDatesModal
  | ISetLodgingIdInFocus
  | ISetLodgingIdHovered
  | ISetSearchedDates
  | ISetSearchedDestinationResult
  | ISetSearchedOriginResult
  | ISetHasViewedUnavailableHotel
  | ISetSelectedLodgingIndex
  | ISetSearchedTravelers
  | ISetSearchedRoomsCount
  | ISetFreeCancelFilter
  | IFetchViewHotelsNearLocationCategories
  | ISetViewHotelsNearLocation
  | ISetPolicyFilter
  | ISetViewHotelsNearLocationCategories
  | IFetchUserHotelPreferences
  | ISetUserHotelPreferences
  | ISetUserHotelPreferencesCallStateFailed
  | IListPaymentMethods
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed
  | ISetFareclassOptionFilter
  | ISetStopsOption
  | ISetPackagesAvailabilityErrors;
