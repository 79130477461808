import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MobilePackagesBookWorkflow } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getRewardsAccounts } from "../../../rewards/reducer";
import {
  getHotelShopChosenRoomInfo,
  getHotelShopDateRange,
  getPackageSelectedLodging,
  selectedPackageByRateId,
} from "../../../hotel-shop/reducer";
import {
  getFareTripDetailsById,
  getRecommendedFlights,
  getSelectedReturnFlightPackage,
  getSelectedTrip,
  selectedFareDetailsSelector,
} from "../../../flight-shop/reducer";
import { PackageFareDetails } from "redmond";
import {
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
} from "../../../availability/reducer";
import { getDestination, getOrigin } from "../../../search/reducer";
import { getFinalizePackageResponse } from "../../../itinerary-review/reducer/selectors";

export const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = getSelectedTrip(state);
  const hotelDates = getHotelShopDateRange(state);
  const fareDetails = selectedFareDetailsSelector(state) as
    | PackageFareDetails
    | undefined;

  const packagePricing = fareDetails?.packagePricing;

  const searchedAdultsCount = getPackageAvailabilityAdultsCount(state);
  const searchedChildren = getPackageAvailabilityChildren(state);
  const searchedInfants = getPackageAvailabilityInfants(state);
  const searchedDestination = getDestination(state);
  const searchedOrigin = getOrigin(state);
  const searchedDepartureDate = getPackagesAvailabilityDepartureDate(state);
  const searchedReturnDate = getPackagesAvailabilityReturnDate(state);

  return {
    finalizePackageResponse: getFinalizePackageResponse(state),
    selectedTrip,
    tripDetails: selectedTrip?.tripId
      ? getFareTripDetailsById(state)[selectedTrip.tripId]
      : undefined,
    selectedLodging: getPackageSelectedLodging(state),
    selectedRoom: getHotelShopChosenRoomInfo(state),
    hotelFromDate: hotelDates?.from,
    hotelUntilDate: hotelDates?.until,
    packagePricing,
    rewardsAccounts: getRewardsAccounts(state),
    searchedAdultsCount,
    searchedChildren,
    searchedInfants,
    searchedDestination,
    searchedOrigin,
    searchedDepartureDate,
    searchedReturnDate,
    recommendedFlights: getRecommendedFlights(state),
    opaqueQuoteRequest: selectedPackageByRateId(state)?.opaqueQuoteRequest,
    opaquePackageToken:
      getSelectedReturnFlightPackage(state)?.opaquePackageToken,
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePackagesBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePackagesBookWorkflow = withRouter(
  connector(MobilePackagesBookWorkflow)
);
