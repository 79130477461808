import React, { useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import {
  MobileOutlinedTextInput,
  MobilePopoverCard,
  IconName,
  Icon,
  ActionButton,
  MobileSearchFieldButton,
  PassengerCountPickerType,
  PassengerCountPicker,
  noop,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import * as H from "history";

import "./styles.scss";
import { LocationSearchConnectorProps } from "./container";
import { MobileLocationSearchModal } from "../";
import * as textConstants from "./textConstants";
import {
  FareclassOptionFilter,
  IPassengerCounts,
  IResult,
  ITripTerminus,
  SliceStopCountFilter,
} from "redmond";
import { MobileFareClassSelection } from "../MobileFareClassSelection";
import { NonStopToggle } from "../../../PackagesSearchControl/components/NonstopToggle";
import {
  AVAILABLE,
  getExperimentVariant,
  PACKAGES_FORCED_PAX,
  useExperiments,
} from "../../../../../../context/experiments";

export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: () => void;
  history: H.History;
  isEditLocationModal?: boolean;
  searchOnContinue?: boolean;
  paxCountApplyButtonText?: string;
  onPaxCountButtonClick?: (passCountsToUse?: IPassengerCounts) => void;
  onSelectLocation?: (value: any) => void;
  localOrigin?: ITripTerminus | null;
  setLocalOrigin?: (origin: ITripTerminus | null) => void;
  localDestination?: IResult | null;
  setLocalDestination?: (origin: IResult | null) => void;
  localFareClassFilter?: FareclassOptionFilter;
  setLocalFareClassFilter?: (filter: FareclassOptionFilter) => void;
  localStopsOption?: SliceStopCountFilter;
  setLocalStopsOption?: (stopsOption: SliceStopCountFilter) => void;
}
export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    origin,
    destination,
    travelers,
    onComplete,
    history,
    searchOnContinue = false,
    onPaxCountButtonClick,
    onSelectLocation,
    stopsOption,
    setStopsOption,
    fareclassOptionsFilter,
    setFareclassOptionFilter,
    setTravelers,
    localOrigin,
    setLocalOrigin,
    localDestination,
    setLocalDestination,
    paxCountApplyButtonText,
    localFareClassFilter,
    setLocalFareClassFilter,
    localStopsOption,
    setLocalStopsOption,
  } = props;
  const [openOriginModal, setOpenOriginModal] = useState(false);
  const [openDestinationModal, setOpenDestinationModal] = useState(false);
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState<boolean>(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);

  const expState = useExperiments();

  const isForcedPAXExperiment =
    getExperimentVariant(expState.experiments, PACKAGES_FORCED_PAX) ===
    AVAILABLE;

  const isOWRTReadyToContinue = useMemo(() => {
    return (
      ((origin && destination) || (localOrigin && localDestination)) &&
      !!travelers.adultsCount
    );
  }, [
    origin,
    destination,
    localOrigin,
    localDestination,
    travelers.adultsCount,
  ]);

  const numTravelers =
    travelers.adultsCount +
    travelers.children.length +
    travelers.infants.length;
  const numTravelerString = !!numTravelers
    ? `${numTravelers} traveler${numTravelers === 1 ? "" : "s"}`
    : "Travelers";

  React.useEffect(() => {
    if (!isForcedPAXExperiment) {
      setTravelers({ adultsCount: 2, children: [], infants: [] });
    }
  }, [isForcedPAXExperiment]);

  React.useEffect(() => {
    if (!!travelers.adultsCount) {
      setHasMissingSearchInfoError(false);
    }
  }, [travelers.adultsCount]);

  const renderRouteSearch = () => {
    return (
      <Box className="mobile-location-pickers">
        <MobileSearchFieldButton
          value={localOrigin?.label || origin?.label}
          label={textConstants.WHERE_FROM}
          icon={<Icon name={IconName.B2BMapPin} />}
          renderPopup={() => (
            <MobileLocationSearchModal
              openLocationModal={openOriginModal}
              handleClose={() => {
                setOpenOriginModal(false);
              }}
              searchType="origin"
              setOpenLocationModal={setOpenOriginModal}
              history={history}
              headerText={textConstants.WHERE_FROM}
              localOrigin={localOrigin}
              setLocalOrigin={setLocalOrigin}
              onSelectLocation={onSelectLocation}
            />
          )}
          onClick={() => setOpenOriginModal(true)}
        />
        <MobileSearchFieldButton
          value={localDestination?.label || destination?.label}
          label={textConstants.WHERE_TO}
          icon={<Icon name={IconName.B2BMapPin} />}
          renderPopup={() => (
            <MobileLocationSearchModal
              openLocationModal={openDestinationModal}
              handleClose={() => setOpenDestinationModal(false)}
              searchType="destination"
              setOpenLocationModal={setOpenDestinationModal}
              history={history}
              headerText={textConstants.WHERE_TO}
              localDestination={localDestination}
              setLocalDestination={setLocalDestination}
              onDestinationSelection={() => {
                if (!travelers.adultsCount) {
                  setHasMissingSearchInfoError(true);
                }

                return !searchOnContinue ? onComplete : undefined;
              }}
              onSelectLocation={onSelectLocation}
            />
          )}
          onClick={() => setOpenDestinationModal(true)}
        />
      </Box>
    );
  };

  const handleTravelersChanged = (counts: PassengerCountPickerType) => {
    setOpenPassengerCountPicker(false);
    onPaxCountButtonClick?.(counts as IPassengerCounts);
    if ("adultsCount" in counts) {
      setTravelers({
        adultsCount: counts.adultsCount,
        children: counts.childrenAges || [],
        infants: counts.infants || [],
      });
    }
  };

  const renderBottomButtons = () => {
    return isOWRTReadyToContinue ? (
      <Box className="mobile-packages-search-buttons-container">
        <ActionButton
          className={clsx("mobile-autocomplete-continue-button", "b2b")}
          message={textConstants.CONTINUE}
          onClick={() => {
            onComplete && onComplete();
          }}
        />
      </Box>
    ) : undefined;
  };

  const renderErrorBanner = () => {
    if (!hasMissingSearchInfoError) return;
    return (
      <Box className="missing-info-search-error-container">
        <NotificationBanner
          className="missing-info-search-error-banner"
          label={textConstants.MISSING_INFO_SEARCH_ERROR}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.WarningAlert} />}
        />
      </Box>
    );
  };

  return (
    <Box className="mobile-packages-search-location ">
      <Box className={clsx("mobile-traveler-count-container")}>
        <Box
          className={clsx("mobile-traveler-count", {
            "has-error": hasMissingSearchInfoError,
          })}
        >
          <MobileOutlinedTextInput
            className={clsx("num-traveler-count", "mobile")}
            prefixString={numTravelerString}
            icon={faUser}
            showCloseIcon={false}
            customEndIcon={<Icon name={IconName.Dropdown} />}
            onClick={() => setOpenPassengerCountPicker(true)}
            value={""}
          />
        </Box>

        <Box className="mobile-fareclass-wrapper">
          <MobileFareClassSelection
            fareclassOptionFilter={
              localFareClassFilter ?? fareclassOptionsFilter
            }
            setFareclassOptionFilter={(fareclassOptionFilter) =>
              setLocalFareClassFilter
                ? setLocalFareClassFilter(fareclassOptionFilter)
                : setFareclassOptionFilter(fareclassOptionFilter)
            }
          />
        </Box>
        <Box className="mobile-nonstop-toggle-wrapper">
          <NonStopToggle
            checked={
              localStopsOption === SliceStopCountFilter.NONE ||
              stopsOption === SliceStopCountFilter.NONE
            }
            onClick={(checked) =>
              setLocalStopsOption
                ? setLocalStopsOption(
                    checked
                      ? SliceStopCountFilter.NONE
                      : SliceStopCountFilter.ANY_NUMBER
                  )
                : setStopsOption(
                    checked
                      ? SliceStopCountFilter.NONE
                      : SliceStopCountFilter.ANY_NUMBER
                  )
            }
          />
        </Box>
      </Box>
      {renderRouteSearch()}
      {renderErrorBanner()}

      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        onClose={() => setOpenPassengerCountPicker(false)}
        centered={true}
      >
        <PassengerCountPicker
          counts={{
            adultsCount: travelers.adultsCount,
            children: travelers.children,
            childrenCount: travelers.children.length,
            infants: travelers.infants,
            infantsInSeatCount: travelers.infants.filter(({ inSeat }) => inSeat)
              .length,
            infantsOnLapCount: travelers.infants.filter(({ inSeat }) => !inSeat)
              .length,
          }}
          minimumAdultsCount={1}
          onClickApply={handleTravelersChanged}
          className="b2b"
          includeChildrenInMaxCount
          setPassengerCounts={noop}
          showChildAgeInputs
          showInfantAgeInputs
          titles={textConstants.TRAVELERS_SELECT_TITLES}
          buttonText={paxCountApplyButtonText}
        />
      </MobilePopoverCard>
      {renderBottomButtons()}
    </Box>
  );
};
